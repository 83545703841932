<template>
  <div>
    <Checkbox v-bind="{
                ...$props,
                label: attestationLabel,
                value: checkboxValue
              }"
              @change="updateValue" />
  </div>
</template>

<script>
  import Checkbox from '@/components/form/Checkbox.vue'
  import { agentAttestation } from '@/scripts/attestation-constants.js' 

  export default {
    name: 'Attestation',
    components: {
      Checkbox
    },
    props: {
      value: [String, Object],
      label: String,
      rules: String,
      errors: Array,
      useGlobalAgentAttestation: Boolean,
      module: String,
      disabled: Boolean,
    },
    emits: [
      'change'
    ],
    data() {
      return {
        newValue: null,
        userId: this.$store.getters.getUserEmail,
        userEmail: this.$store.getters.getUserEmail,
        userName: this.$store.getters.getUserName,
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(newVal) {
          this.newValue = newVal;
        }
      },
      newValue() {
        this.emitValue();
      }
    },
    computed: {
      checkboxValue() {
        if (this.newValue === undefined || this.newValue === null || this.newValue === false) {
          return false;
        } else {
          return true;
        }
      },
      attestationLabel() {
        return this.formatAttestationLabel(this.label);
      }
    },
    methods: {
      updateValue(event) {
        if (event === false) {
          this.newValue = null;
        } else if (event === true && !this.newValue) {
          this.newValue = {
            UserId: this.userId,
            UserEmail: this.userEmail,
            UserName: this.userName,
            DateTime: new Date(),
            Text: this.formatAttestationLabel(this.label)
          }
        }
      },
      emitValue() {
        this.$emit('change', this.newValue)
      },
      formatAttestationLabel(label) {
        if (this.useGlobalAgentAttestation) {
          label = '<strong>This is my electronic signature.</strong> ' + agentAttestation

          if (this.module) {
            this.$store.commit('updateFormControl', {
              module: this.module,
              name: 'AgentAttestationText',
              value: label
            });
          }
        }

        const USERNAME = this.$store.getters.getUserName;
        let entityName = this.$store.getters.getControlValue('additionalForm', 'EntityName') || "";
        let firstName = this.$store.getters.getControlValue('quoteForm', 'FirstName') || "";
        let lastName = this.$store.getters.getControlValue('quoteForm', 'LastName') || "";
        const INSUREDNAME = entityName ? entityName : firstName + ' ' + lastName;
        return label
          .split("%%INSUREDNAME%%").join(INSUREDNAME)
          .split("%%USERNAME%%").join(USERNAME);
      }
    }
  }
</script>
